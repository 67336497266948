import React from 'react';

const CustomVideoPlayer = ({subtitles,url}) => {
  return (
    <div>
      <video id="sample_video" width="auto" height="500" controls>
        <source src={`${url}`} type="video/mp4" />
        <track label="English" kind="subtitles" srclang="en" src={`data:text/vtt;charset=utf-8,${encodeURIComponent(subtitles)}`} default />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default CustomVideoPlayer;
