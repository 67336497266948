import React, { useState } from 'react';
import CustomVideoPlayer from "./components/CustomVideoPlayer";
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';

const App = () => {
  const [url, setUrl] = useState("");
  const [userSubtitles, setUserSubtitles] = useState('WEBVTT \n \n');
  const [playerKey, setPlayerKey] = useState(0);
  const [startTime, setStartTime] = useState("");
  const [subtitleText, setSubtitleText] = useState("");
  const [endTime, setEndTime] = useState("");

  const applySubtitles = () => {
    setPlayerKey(playerKey + 1);
  };

  const addSubtitleLine = () => {
    const newSubtitle = `${startTime}.000 --> ${endTime}.000\n${subtitleText}\n\n`;
    setUserSubtitles(prevSubtitles => prevSubtitles + newSubtitle);
    setStartTime("");
    setSubtitleText("");
    setEndTime("");
  };


  return (
    <>
      <nav className="navbar navbar-dark bg-dark">
        <span className="navbar-brand mb-0 mx-2 h1">Subtitles Addition - Letterdrop Submission</span>
      </nav>
      <div className='main'>
        {playerKey > 0 ? <CustomVideoPlayer key={playerKey} url={url} subtitles={userSubtitles} /> : ""}
        <input
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          placeholder="Paste your video URL here..."
          size={90}
        /><br /><br /><hr />
        <p>You can either directly add entire subtitles text in the textbox below or add line by line using timestamps.</p><br />
        <textarea
          value={userSubtitles}
          onChange={(e) => setUserSubtitles(e.target.value)}
          placeholder="Paste your subtitles here..."
          rows={10}
          cols={50}
        /><br />
        <div>
          <input
            type="text"
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
            placeholder="Start Time (hh:mm:ss)"
          />
          <input
            type="text"
            value={subtitleText}
            onChange={(e) => setSubtitleText(e.target.value)}
            placeholder="Subtitle Text"
          />
          <input
            type="text"
            value={endTime}
            onChange={(e) => setEndTime(e.target.value)}
            placeholder="End Time (hh:mm:ss)"
          /><br /></div>
        <button className='btn btn-primary' onClick={addSubtitleLine}>Add Subtitle Line</button>
        <br />
        {/* Button to apply subtitles */}
        <button className='btn btn-secondary btn-lg btn-block' onClick={applySubtitles}>Apply Subtitles and Play Video</button>


      </div>
    </>
  );
};

export default App;
